import * as types from "../actions/action-types";

const initialState = [];
export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case types.INIT_EVENTS:
      return Object.assign({}, state, {
        events: action.events,
      });
    case types.EVENT_DETAIL:
      return Object.assign({}, state, {
        eventDetail: action.eventDetail,
      });
    case types.EVENT_CONFIGURATION:
      return Object.assign({}, state, {
        eventConfiguration: action.eventConfiguration,
      });
    case types.EVENT_RESULTS:
      return Object.assign({}, state, {
        eventResults: action.eventResults,
        eventResultsOriginal: action.eventResultsOriginal,
      });
    case types.EVENT_RESULTS_REGISTRY:
      return Object.assign({}, state, {
        eventResultsRegistries: action.eventResultsRegistries,
      });
    case types.EVENT_RESULTS_REGISTRY_OBJECT:
      return Object.assign({}, state, {
        eventResultsRegistriesObject: action.eventResultsRegistriesObject,
      });
    case types.ATHLETE_JUEZ_REGISTRY:
      return Object.assign({}, state, {
        registryJuez: action.registryJuez,
      });
    case types.EVENT_POINTS_CONTROLS:
      return Object.assign({}, state, {
        puntosControl: action.puntosControl,
      });
    case types.EVENT_PHOTOS_CONFIGURATION:
      return Object.assign({}, state, {
        eventPhotoConfiguration: action.eventPhotoConfiguration,
      });
    case types.EVENT_CONVOCATORY:
      return Object.assign({}, state, {
        eventConvocatory: action.eventConvocatory,
      });
    case types.EVENT_WEB:
      return Object.assign({}, state, {
        eventWeb: action.eventWeb,
      });
    case types.EVENT_SUBEVENTS:
      return Object.assign({}, state, {
        eventSubEvents: action.eventSubEvents,
        eventSubEventsSnap: action.eventSubEventsSnap,
      });
    case types.EVENT_SUBEVENTS_REGISTRY:
      return Object.assign({}, state, {
        eventSubEventsRegistry: action.eventSubEventsRegistry,
        eventSubEventsRegistrySnap: action.eventSubEventsRegistrySnap,
      });
    case types.EVENT_USERS:
      return Object.assign({}, state, {
        eventUsers: action.eventUsers,
      });
    case types.CUSTOM_STRINGS:
      return Object.assign({}, state, {
        customStrings: action.customStrings,
      });
    case types.EVENT_DATA_TO_COPY:
      return Object.assign({}, state, {
        eventDataToCopy: action.eventDataToCopy,
      });
    case types.EVENT_DEPOSITS_LIST:
      return Object.assign({}, state, {
        eventDepositsList: action.eventDepositsList,
      });
    case types.EVENT_DEPOSITS_REQUESTED:
      return Object.assign({}, state, {
        eventDepositsRequested: action.eventDepositsRequested,
      });
    case types.EVENT_DEPOSITS_USERS:
      return Object.assign({}, state, {
        eventDepositsUsers: action.eventDepositsUsers,
      });
    case types.EVENTS_ACCOUNT:
      return Object.assign({}, state, {
        eventsAccount: action.eventsAccount,
      });
    case types.CUSTOM_VIEWS:
      return Object.assign({}, state, {
        customViews: action.customViews,
      });
    case types.TRACKING_LINKS:
      return Object.assign({}, state, {
        trackingLinks: action.trackingLinks,
      });
    case types.LOCAL_REGISTRIES:
      return Object.assign({}, state, {
        localRegistries: action.localRegistries,
      });
    case types.EVENT_CHECKCHIP:
      var key = action.checkChipKey;
      return Object.assign({}, state, {
        checkChip: Object.assign({}, state.checkChip, {
          [key]: action.checkChip,
        }),
      });

    default:
      return state;
  }
}
