import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import createEventConfig from "./EventCreate/createEventconfig.json";
import { updateEventAttribute } from "../../../../data-store/actions/events-actions";
import {
  generateVirtualEventsLink,
  sendAllMails,
} from "../../../../data-store/actions/registry-actions";
import EditableField from "../../../EditableField/EditableField";
import EventRaceNumber from "./EventRaceNumber/EventRaceNumber";
import EventRegistryCopyPassEventsConfig from "./EventRegistryCopyPassEventsConfig";
import { timestampToDate } from "../../../../services/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "0px",
    textAlign: "left",
    minHeight: "500px",
    overflowY: "scroll",
  },
}));

export default function EventRegistryConfig() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const eventParticipants = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );

  const createEventObject = createEventConfig.inscripcionesConfiguracion;

  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscripcionesConfiguracion/",
        value
      )
    );
  }
  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log(fieldName);
    console.log(value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }
  function sendCodes() {
    dispatch(
      generateVirtualEventsLink(
        eventParticipants,
        "evento_" + eventDetail.iDEvento,
        eventSubEventsRegistry
      )
    );
  }
  function sendMails() {
    dispatch(
      sendAllMails(
        eventParticipants,
        "evento_" + eventDetail.iDEvento,
        eventSubEventsRegistry
      )
    );
  }
  function updateDate() {
    console.log("updateDate");

    console.log(eventParticipants);
    for (var i in data) {
      console.log(eventDetail.iDEvento, i, data[i], eventParticipants[i], {
        fechaRegistro: data[i],
        serverTime: new Date(data[i]).getTime(),
      });

      //   if (eventParticipants[i]) {
      //     dispatch(
      //       updateEventAttribute(
      //         "evento_" + eventDetail.iDEvento,
      //         "inscritos/listadoInscritos/" + i + "/",
      //         { fechaRegistro: data[i] }
      //       )
      //     );
      //     dispatch(
      //       updateEventAttribute(
      //         "evento_" + eventDetail.iDEvento,
      //         "inscritos/listadoInscritos/" + i + "/",
      //         { serverTime: new Date(data[i]).getTime() }
      //       )
      //     );
      //     dispatch(
      //       updateEventAttribute(
      //         "evento_" + eventDetail.iDEvento,
      //         "inscritos/listadoInscritos/" + i + "/statusPago/",
      //         { fechaPago: data[i] }
      //       )
      //     );
      //   }
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h4"} component="h2">
            Configuración de inscripciones
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
          {Object.values(createEventObject).map((field, index) => {
            const defaultValue = findValueByRoute(
              registryConfiguration,
              field.fieldName
            );
            return (
              <EditableField
                key={index}
                fieldObject={{
                  ...field,
                  defaultValue: defaultValue,
                }}
                onSubmit={handleChange}
              />
            );
          })}
        </Grid>
        {eventParticipants === null && (
          <Grid
            item
            xs={12}
            syle={{ margin: 30, padding: 30, textAlign: "center" }}
          >
            <Typography gutterBottom variant={"h4"} component="h2">
              Copiar configuración de eventos anteriores
            </Typography>
            <Typography gutterBottom variant={"subtitle1"} component="h2">
              NOTA: Esta Opción quedará deshabilitada apenas se registre el
              primer atleta.
            </Typography>
            <SimpleDialogDemo
              paddingButton={40}
              buttonText={"Abrir listado de eventos para copiar"}
            >
              <EventRegistryCopyPassEventsConfig />
            </SimpleDialogDemo>
          </Grid>
        )}
        {eventParticipants !== null && (
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant={"h5"}
              component="h2"
              syle={{ margin: 30, padding: 30 }}
            >
              Función de copiar configuracion deshabilitada por que ya existen
              registros de inscritos
            </Typography>
          </Grid>
        )}
        <Typography
          gutterBottom
          variant={"h5"}
          component="h2"
          syle={{ margin: 50, padding: 50 }}
        ></Typography>
      </Grid>
      {/* <Button onClick={() => sendCodes()}>Generar Códigos</Button>
      <Button onClick={() => updateDate()}>Update Dates</Button>
       */}
      <Button onClick={() => sendMails()}>Enviar correos</Button>
    </Paper>
  );
}
const data = {
  "-Nn2GD8y7B6ewSJ7PgLd": "2024/01/10 22:25:20",
};
