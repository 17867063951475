import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { updateEventObject } from "../../../../../data-store/actions/events-actions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    backgroundColor: theme.palette.background.default,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  inputLabelTitle: {
    color: theme.palette.primary.main,
    alignItems: "center",
  },
}));
export default function ConvertRegistryDataToTimingDataTable() {
  const dispatch = useDispatch();
  const [arrayRegistry, setArrayRegistry] = useState(false);
  const [arrayTiming, setArrayTiming] = useState(false);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventResults = useSelector((state) => state.events.eventResults);

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  useEffect(() => {
    if (eventSubEvents && eventSubEventsRegistry) {
      setArrayModGenCat(eventSubEventsRegistry, eventSubEvents);
    }
  }, [eventSubEvents, eventSubEventsRegistry]);

  function setArrayModGenCat(registry, timing) {
    timing = Object.values(timing).sort((a, b) => a.order - b.order);

    let arrayTimingNew = {};
    for (var i in timing) {
      const modalidad = timing[i];

      if (!modalidad.hide) {
        const ramas = modalidad.ramaCat;
        for (var e in ramas) {
          const rama = ramas[e];
          const categorias = rama.categorias;
          for (var f in categorias) {
            const categoria = categorias[f];
            arrayTimingNew[
              modalidad.codigoModalidad +
                "-" +
                rama.rama +
                "-" +
                categoria.codigo
            ] = {
              text:
                modalidad.descripcionModalidad +
                " - " +
                rama.rama +
                " - " +
                categoria.nombreCategoria +
                " - Salida:" +
                categoria.serie,
              data: {
                modalidad: modalidad.codigoModalidad,
                rama: rama.rama,
                categoria: categoria.codigo,
                serie: categoria.serie,
              },
            };
          }
        }
      }
    }
    setArrayTiming(arrayTimingNew);

    registry = Object.values(registry).sort((a, b) => a.order - b.order);

    let arrayRegistryNew = {};
    for (var x in registry) {
      const modalidad = registry[x];

      if (!modalidad.hide) {
        const ramas = modalidad.ramaCat;
        for (var y in ramas) {
          const rama = ramas[y];
          const categorias = rama.categorias;
          for (var z in categorias) {
            const categoria = categorias[z];
            arrayRegistryNew[
              modalidad.codigoModalidad +
                "-" +
                rama.rama +
                "-" +
                categoria.codigo
            ] = {
              text:
                modalidad.descripcionModalidad +
                " - " +
                rama.rama +
                " - " +
                categoria.nombreCategoria,
              translate:
                registry && categoria.modGenCatCrono
                  ? categoria.modGenCatCrono
                  : null,
              translateText:
                registry &&
                categoria.modGenCatCrono &&
                arrayTimingNew[
                  categoria.modGenCatCrono.modalidad +
                    "-" +
                    categoria.modGenCatCrono.genero +
                    "-" +
                    categoria.modGenCatCrono.categoria
                ]
                  ? arrayTimingNew[
                      categoria.modGenCatCrono.modalidad +
                        "-" +
                        categoria.modGenCatCrono.genero +
                        "-" +
                        categoria.modGenCatCrono.categoria
                    ].text
                  : null,
            };
          }
        }
      }
    }
    setArrayRegistry(arrayRegistryNew);
  }
  function getModGenCatCrono(
    subEventsTiming,
    subEventsRegistry,
    mod,
    gen,
    cat
  ) {
    let modGenCatCrono = false;

    if (
      subEventsRegistry &&
      subEventsRegistry["modalidad_" + mod] &&
      subEventsRegistry["modalidad_" + mod].ramaCat["rama_" + gen] &&
      subEventsRegistry["modalidad_" + mod].ramaCat["rama_" + gen].categorias[
        "categoria_" + cat
      ]
    ) {
      modGenCatCrono =
        subEventsRegistry["modalidad_" + mod].ramaCat["rama_" + gen].categorias[
          "categoria_" + cat
        ].modGenCatCrono;
    }
    if (
      subEventsTiming &&
      modGenCatCrono &&
      subEventsTiming["modalidad_" + modGenCatCrono.modalidad] &&
      subEventsTiming["modalidad_" + modGenCatCrono.modalidad].ramaCat[
        "rama_" + modGenCatCrono.genero
      ] &&
      subEventsTiming["modalidad_" + modGenCatCrono.modalidad].ramaCat[
        "rama_" + modGenCatCrono.genero
      ].categorias["categoria_" + modGenCatCrono.categoria]
    ) {
      return {
        codMod: modGenCatCrono.modalidad,
        mod: subEventsTiming["modalidad_" + modGenCatCrono.modalidad].modalidad,
        gen: modGenCatCrono.genero,
        category:
          subEventsTiming["modalidad_" + modGenCatCrono.modalidad].ramaCat[
            "rama_" + modGenCatCrono.genero
          ].categorias["categoria_" + modGenCatCrono.categoria],
      };
    } else {
      return false;
    }
  }
  function setEventResults() {
    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "resultados/",
        migrateData(eventParticipantsData)
      )
    );
  }
  function compareData(result, participant) {
    const changes = {};
    let resultNew = convertAthelteRegistryToTiming(
      participant,
      eventSubEvents,
      eventSubEventsRegistry
    );
    if (resultNew === null) {
      resultNew = {};
    }
    console.log("participant123", resultNew);

    let haveChanges = false;

    changes["nombres"] =
      result.nombres !== resultNew.nombres ? (haveChanges = true) : false;
    changes["gen"] =
      result.gen !== resultNew.gen ? (haveChanges = true) : false;
    changes["codCat"] =
      result.codCat !== resultNew.codCat ? (haveChanges = true) : false;
    changes["cat"] =
      result.cat !== resultNew.cat ? (haveChanges = true) : false;
    changes["codMod"] =
      result.codMod !== resultNew.codMod ? (haveChanges = true) : false;
    changes["serie"] =
      result.serie !== resultNew.serie ? (haveChanges = true) : false;

    console.log("compareDate", result, resultNew, changes);

    return { changes: changes, haveChanges: haveChanges };
  }

  function convertAthelteRegistryToTiming(
    participant,
    subEventTiming,
    subEventsRegistry
  ) {
    const modGenCatCrono = getModGenCatCrono(
      subEventTiming,
      subEventsRegistry,
      participant.modalidad / 1,
      participant.rama,
      participant.categoria / 1
    );
    console.log(
      "convertAthelteRegistryToTiming",
      participant,
      subEventTiming,
      subEventsRegistry,
      modGenCatCrono
    );

    if (!modGenCatCrono) {
      return null;
    }

    let extraName = participant.nombredelsegundointegrantedelequipo
      ? " - " + participant.nombredelsegundointegrantedelequipo
      : "";

    extraName = participant.nombredeltercerintegrantedelequipo
      ? extraName + " - " + participant.nombredeltercerintegrantedelequipo
      : extraName;

    return {
      ab: 0,
      cat: modGenCatCrono.category.nombreCategoria,
      cg: modGenCatCrono.category.cantidadGanadores,
      codCat: modGenCatCrono.category.codigo,
      codMod: modGenCatCrono.codMod,
      fhm: "0000-00-00 00:00:00.000",
      gen: modGenCatCrono.gen,
      mod: modGenCatCrono.mod,
      mp: 0,
      nombre: (
        participant.nombres +
        " " +
        participant.apellidos +
        extraName
      ).toUpperCase(),
      num: participant.numero / 1,
      pCat: 0,
      pG: 0,
      pR: 0,
      registroSalida: false,
      serie: modGenCatCrono.category.serie,
      tc: "00:00:00",
      to: "00:00:00",
    };
  }
  function migrateData(participants) {
    const validParticipants = Object.values(participants).filter(
      (a) => a.statusPago.status === "ok" && a.numero / 1 > 0
    );

    //const copyAll = eventResults.length === 0 ? true : false;

    let dataTiming = {};
    for (var i in validParticipants) {
      console.log("participant123", validParticipants[i]);

      dataTiming[
        "num_" + validParticipants[i].numero
      ] = convertAthelteRegistryToTiming(
        validParticipants[i],
        eventSubEvents,
        eventSubEventsRegistry
      );
      console.log("participant123", dataTiming);
    }
    console.log("participant123", dataTiming);
    return dataTiming;
  }

  console.log("setArrayModGenCat", arrayRegistry);
  console.log("setArrayModGenCat", arrayTiming);
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Typography
        style={{ margin: "10px" }}
        className={classes.inputLabel}
        variant={"h3"}
      >
        Tabla de datos
      </Typography>
      <Button onClick={() => setEventResults()}>Migrar Datos</Button>
      <Table aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.inputLabel}>Número</TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Nombres
            </TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Registro
            </TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Cambio
            </TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Crono
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventParticipantsData &&
            Object.values(eventParticipantsData)
              .filter((a) => a.statusPago.status === "ok" && a.numero / 1 > 0)
              .map((a) => (
                <TableRow>
                  <TableCell className={classes.inputLabel} align="center">
                    {a.numero}
                  </TableCell>
                  <TableCell className={classes.inputLabel} align="center">
                    {a.nombres} {a.apellidos}
                  </TableCell>
                  <TableCell className={classes.inputLabel} align="center">
                    {arrayRegistry &&
                      arrayRegistry[
                        a.modalidad + "-" + a.rama + "-" + a.categoria
                      ] &&
                      arrayRegistry[
                        a.modalidad + "-" + a.rama + "-" + a.categoria
                      ].text}
                  </TableCell>
                  <TableCell className={classes.inputLabel} align="center">
                    {arrayRegistry &&
                      arrayRegistry[
                        a.modalidad + "-" + a.rama + "-" + a.categoria
                      ] &&
                      arrayRegistry[
                        a.modalidad + "-" + a.rama + "-" + a.categoria
                      ].translateText}
                  </TableCell>
                  <TableCell className={classes.inputLabel} align="center">
                    {eventResults.filter((result) => result.num === a.numero)
                      .length > 0
                      ? compareData(
                          eventResults.filter(
                            (result) => result.num === a.numero
                          )[0],
                          a
                        ).haveChanges
                        ? "Hay Cambios"
                        : "Sin Cambios"
                      : "No existe"}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
